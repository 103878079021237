@import '../../../../../styles/bootstrap/bootstrap-grid';
@import '../../../../../styles/variables/all';
@import '../../../../../styles/components/all';
@import '../../../../../styles/reset';

.component-pallet-fee-line {
    margin-top: 3rem;
    border-top: 1px solid $light;

    .pallet-fee-line-description {
        display: flex;

        .title {
            display: inline;
            font-size: 1.125rem;
            font-weight: 500;
            margin-top: 0.5rem;
            margin-right: 3rem;
        }
    }

    .pallet-fee-line-price-info {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 2rem;

        .pallet-fee-line-price-description {
            display: flex;
            font-weight: bold;

            .separator {
                margin: 0 .3rem;
            }
        }

        .pallet-fee-line-total-price {
            font-weight: bold;
        }
    }
}
