@import '../../../../../styles/bootstrap/bootstrap-grid';
@import '../../../../../styles/variables/all';
@import '../../../../../styles/components/all';
@import '../../../../../styles/reset';

.component-order-shipment-line {
    margin-top: 3rem;
    border-top: 1px solid $light;
    display: flex;
    justify-content: space-between;

    .shipment-line-description {
        display: flex;

        .title {
            display: inline;
            font-size: 1.125rem;
            font-weight: 500;
            margin-top: 0.5rem;
            margin-right: 3rem;
        }
    }

    .shipment-line-total-price {
        font-weight: bold;
    }
}
