@import '../../../../../styles/variables/all';
@import '../../../../../styles/reset';
@import '../../../../../styles/variables/all';
@import '../../../../../styles/components/all';
@import '../../../../../styles/bootstrap/bootstrap-grid';
@import '../../../../../styles/bootstrap/bootstrap-reboot';
@import '../../../../../styles/bootstrap/bootstrap';

.warehouse-info-table-container {
    background-color: $medium;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    * {
        color: $white !important;
    }

    @include media-breakpoint-up(xl) {
        height: calc(100% - 83px);
        margin-top: 83px;
    }

    @include media-breakpoint-down(xl) {
        height: 100%;
    }

    .available-lot {
        .lot-location {
            margin-left: 5px;
        }
    }

    .unavailable-lots-container {
        background-color: $primary;
        padding: 1rem;
    }

    .available-lots-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2rem;
    }
}
