html, body {
    // background-color: rgba($white,0);
    color: $dark;
    font-family: 'Jost*', sans-serif;
    line-height: 1.4;position: relative;
    transition: .3s;
    z-index: -10;

}

body {
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    width: 100vw;
}

html {
    overflow: unset;
}

#root,
.co-app {
    color: #262626;
    font-family: 'Jost*', sans-serif;
    line-height: 1.4;
    position: relative;
    transition: .3s;
}

main {
    z-index: 10;
    // background-color: $white;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 900;
    line-height: 1.4;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.375rem;
}

h3 {
    font-size: 1.875rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1.125rem;
    padding-top: 1rem;
}

p {
    font-size: 1rem;
    line-height: 1.3;
    margin-bottom: .5rem;
}

.small {
    font-size: 85%;
}

// Font type
.f-sans {
    font-family: 'DM Sans', sans-serif;
}

.f-serif {
    font-family: 'NewYork', serif;
}

// Color
.c-primary {
    color: $primary;
}

.c-secondary {
    color: $secondary;
}

.c-tertiary {
    color: $tertiary;
}

// Background Colors
.bg-white {
    background-color: $white !important;
}

.bg-light {
    background-color: $light !important;
}

.bg-medium {
    background-color: $medium !important;
}

.bg-dark {
    background-color: $dark !important;
}

.bg-primary {
    background-color: $primary !important;
}

.bg-secondary {
    background-color: $secondary !important;
}

.bg-tertiary {
    background-color: $tertiary !important;
}

// Visibility
.only-desktop {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.only-mobile {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.screen > .container {
    margin-top: 5px;
    padding-top: 5px;
}

.screen-container.l-modal {
    width: 420px;
    .header {
        text-align: center;
        margin-bottom: 15px;
        .section-title {
            font-size: 16px;
        }
    }
    .body{
        .action-button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.form-field {
    margin-bottom: 10px;
}

// login cover container
.login-page {

    height: 100vh;
    min-height: 46rem;min-width: 100vw;
    // background-image: linear-gradient(rgba($dark, 0.6), rgba($dark, 0.6)), url("../img/bg-01.jpg");
    // background-repeat: no-repeat;
    // background-position: center center;
    // background-size: cover;

    .login-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100vh;justify-content: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 72rem;
        min-height: 46rem;
        padding: 3rem 1.25rem;
        width: 100%;
        word-break: break-word;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        .login-page-text {

            padding: 0 1rem 2rem 0;width: 100%;
        }


        .login-welcome {color: $primary;
            font-family: 'DM Sans', sans-serif;
            font-size: 1.75rem;
            font-weight: 300;
            margin-bottom: 1.25rem;

            @include media-breakpoint-down(sm) {
                font-size: 1.25rem;}
            }


        .login-title {color: $white;
            font-size: 4rem;
            line-height: 1;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                font-size: 2.75rem;
            }
        }

        .login-description {
            color: $light;
            font-family: 'Jost*', sans-serif;font-size: 1.25rem;
            font-weight: 300;
            line-height: 1.4;
            margin-top: 2rem;

            @include media-breakpoint-down(lg) {
                margin-bottom: .25rem;}

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
                margin-top: .25rem;
            }
        }

        .login-page-form {
            background-color: rgba($white, 1);
            padding: 5rem 3.5rem 3rem ;
            width: 100%;
            @include media-breakpoint-down(lg) {
                padding: 2rem 1.25rem;
            }

            .login-form-title {color: $primary;
                font-family: 'DM Sans', sans-serif;
                font-size: 1.5rem;

                font-weight: 100;
                margin-bottom: 0;
            }

            .login-form-subtitle {color: rgba($medium, .6);
                font-family: 'DM Sans', sans-serif;
                font-size: .875rem;

                font-weight: 100;
                margin-bottom: 2rem;
            }
        }

        .password-field {
            position: relative;

            .visibility-button {
                background-color: transparent;
                position: absolute;
                right: 20px;
                top: 30px;
                border: none;
            }
        }

    }
}

.order-detail-header-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
        justify-content: flex-start;
    }
}

.header-actions {
    align-items: flex-start;display: flex;

    flex-direction: row;

    flex-wrap: wrap;
justify-content: space-between;
    padding-bottom: 2rem;
    width: 100%;

    .actions {
        align-items: center;display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: flex-end;
        margin-left: auto;
        cursor: pointer;

        width: auto;

        p {align-items: center;
            display: flex;
            margin: 0;


            img {
                margin-right: .5rem;
            }
        }

        .btn {
            display: inline-block;
            margin-left: 2rem;
            min-width: 9rem;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            width: inherit;
        }
    }
}

.section-title > .header-actions {
    display: inline;float: right;

    width: auto;

    > .btn {
        margin-left: 5px;
    }
}

.unavailable {
    filter: grayscale(80%);opacity: .6;


    &:hover {

        filter: grayscale(10%);
    opacity: 1;
    }
}

// CSS impide seleccionar los elementos de la web !
body {
    user-select: none; /* Safari */
    user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.mr-25 {
    margin-right: 0;

    @include media-breakpoint-up(sm) {
        margin-right: .3rem;
    }
}

.custom-dark {
    color: $dark !important;
    text-decoration: none !important;
}

.w-100 {
    width: 100%;
}

.navbar-toggler-icon svg {
    vertical-align: initial;
}

.location p {
    margin-bottom: 0;
}

.errorMessage {
    color: #DC3545;
    display: block;
    margin-top: 1.5rem;
    text-align: center;
}

.errorServerMessage {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 59vh;
}

.centeredButton {
    display: block;

    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    width: 100%;
}

.unavailable {
    filter: grayscale(80%);opacity: .6;


    &:hover {

        filter: grayscale(10%);
    opacity: 1;
    }
}

.form-search {
    width: auto;
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.banner-section-title {
    color: $primary;
    font-size: 4.5rem;word-wrap: break-word;


    span {
        color: $dark;
    }
}

.page-banner-cart {
    background-color: $tertiary;
    color: $white;
    display: flex;
    font-family: 'Jost*';
    font-weight: bold;
    justify-content: space-between;
    line-height: 28px;
    padding: 22px;
    width: 100%;
}

.collection-swiper-wrapper {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    list-style: none;
    margin: 1em auto;
}

.collection-swiper-slider {
    justify-self: center;
    padding: .5rem;
    width: 16rem !important;
}

.button-loading {
    margin-right: .5rem;
}

// Field required
.is-required::after {
    color: $red;
    content: '*';
    font-weight: $co-font-bold;
}

table.orders-list .order-price {
    text-align: right;
}

.footer-actions {

    margin-bottom: 4rem;text-align: right;
}

.navbar-options-container {
    > button {
        color: $medium;

        &:hover {
            color: $primary;
        }
    }

    > a {
        white-space: nowrap;
        &:hover {
            color: $primary;
        }
    }

    .link-active {
        text-decoration: none;
    }

    .link-inactive {
        color: $medium;
        text-decoration: none;
    }

    @include media-breakpoint-down(xl) {
        >a,
        button {
            margin: 1rem 0;
        }
    }

    @include media-breakpoint-up(xl) {
        >a {
            margin: 0 2rem;
        }
    }
}

.btn-check:focus + .btn-success,
.btn-success:focus {
    box-shadow: none;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
}

.basic-spinner-container {
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}
