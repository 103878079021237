.banner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    padding: 1.5rem;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(lg) {
        align-items: center;
    }

    .intro-title {
        background-color: rgba($dark, .5);
        color: rgba($white, .9);
        font-size: 1.25rem;
        margin-left: auto;
        margin-right: auto;
        padding: .75rem;
        text-align: center;
        width: fit-content;
        width: fit-content;
        width: fit-content;
    }

    .home-title {
        color: rgba($white, .9);
        font-size: 4rem;
        font-weight: 100;
        line-height: 1.1;
        padding-bottom: 2rem;
        padding-top: 3rem;
        text-transform: uppercase;

        @include media-breakpoint-down(lg) {
            font-size: 3rem;
            padding-top: 3rem;
        }
    }
}

.wow-features {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-bottom: 2rem;
    padding: 1rem;

    .feature-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;

        .feature-img {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: .25rem;
            padding: .5rem;

            svg {
                height: 3rem;
                opacity: .8;
                width: 3rem;
            }
        }

        .feature-info {
            width: 100%;
        }

        .feature-title {
            background-color: $secondary;
            color: $white;
            display: inline;
            font-weight: 500;
            padding: 0 .5rem;
            text-transform: uppercase;
        }

        .feature-description {
            color: $light;
            font-size: .875rem;
        }

        @include media-breakpoint-down(lg) {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
    }
}

.product-detail-section {
    padding-bottom: 3rem;
    padding-top: 1rem;
}

.wow-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    padding: 4rem 0;
}

.page-title {
    color: $dark;
    font-family: 'DM Sans', sans-serif;
    font-size: 3.5rem;
    font-weight: 900;
    line-height: 1.2;
    word-wrap: break-word;

    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }
}

.section-title {
    color: $dark;
    font-family: 'DM Sans', sans-serif;
    font-size: 2rem;
    font-weight: 900;
    word-wrap: break-word;
}

.product-subtitle {
    color: $medium;
    font-family: 'DM Sans', sans-serif;
    font-size: 1rem;
    font-weight: 100;
    margin-bottom: 0;
    text-transform: uppercase;
}

.product-subtitle-cart {
    color: $medium;
    font-family: 'DM Sans', sans-serif;
    font-size: .7rem;
    font-weight: 100;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
}

.product-subtitle-cart-group {
    align-items: end;
    display: flex;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
}

.product-tag {
    background: rgb(128 128 128 / 5%);
    color: $dark;
    display: inline-block;
    font-size: .75rem;
    line-height: 1;
    padding: 6px;

    .product-tag-label,
    .weight-value,
    .quantity-value {
        font-weight: bold;
        margin-right: 2px;
    }
}

// GRID VIEW
.section-grid {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
    list-style: none;
    margin-bottom: 3rem;

    .grid-item {
        background-color: $white;
        box-shadow: 0 0 1rem rgba($dark, .05);
        cursor: pointer;
        height: 100%;
        transition-duration: .3s;
        width: 100%;

        &:hover {
            box-shadow: 0 1rem 1rem rgba($dark, .15);
            transform: translateY(-.25rem);
        }

        .product-image {
            height: 15rem;
            width: 100%;

            img {
                height: 100%;
                object-fit: cover;
                object-fit: cover;
                width: 100%;
            }
        }

        .grid-item-options {
            margin-left: auto;
            margin-top: -2rem;
            width: fit-content;
            width: fit-content;
            width: fit-content;

            .btn-actions-circle {
                background-color: $light;
                border-radius: 100px;
                height: 3rem;
                padding: .5rem;
                width: 3rem;

                &:hover {
                    background-color: darken($light, 10%);
                }
            }
        }

        .product-item-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1.25rem 1.5rem;
            text-align: left;

            .product-stock {
                background-color: $medium;
                color: $white;
                display: inline-flex;
                font-size: .75rem;
                font-weight: 500;
                left: 0;
                letter-spacing: .03rem;
                padding: .25rem .5rem .25rem 1.5rem;
                padding-left: 1.5rem;
                position: absolute;
                text-transform: uppercase;
                top: 14.25rem;
            }

            .in-stock {
                background-color: $secondary;
            }

            .product-detail-image {
                background-color: rgba($white, 1);
                box-shadow: 0 0 .5rem rgba($dark, .05);
                height: 7rem;
                margin-left: auto;
                margin-top: -6rem;
                padding: .25rem;
                width: 7rem;

                img {
                    height: 100%;
                    object-fit: contain;
                    object-fit: contain;
                    width: 100%;
                }
            }

            .product-title {
                color: $dark;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .product-description,
            .product-size,
            .product-weight,
            .product-location,
            .product-family {
                color: $medium;
                font-size: .875rem;
                font-weight: normal;
                margin-bottom: .5rem;
            }

            .product-size {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                line-height: 1;
            }

            .product-location {
                align-items: flex-start;
                display: flex;
                justify-content: flex-start;
            }

            .product-weight {
                align-items: center;
                display: flex;
                justify-content: flex-start;
            }

            .product-item-footer {
                align-items: flex-end;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .icon-mini {
                height: 1rem;
                margin-right: .5rem;
                width: 1rem;
            }
        }
    }
}

.section-grid > a {
    text-decoration: none;
}

.detail-price {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .total-price {
        margin-right: 2rem;
    }

    @include media-breakpoint-down(lg) {
        justify-content: space-between;
    }
}

.product-price {
    align-items: baseline;
    color: $secondary;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;

    .currency {
        font-size: 1.125rem;
        font-weight: 600;
        margin-right: .25rem;
    }

    .import {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .units-type {
        color: $medium;
        font-size: .75rem;
        margin-top: -.25rem;
        width: 100%;
    }
}

.units-type-mod {
    color: $medium;
    display: block !important;
    font-size: .75rem;
    margin-top: -.25rem;
    width: 100%;
}

.price-section {
    margin-left: -1.5rem;
}

.section-list {
    background-color: $white;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr;
    list-style: none;
    margin-bottom: 4rem;
    padding: 0;

    .list-item {
        align-items: center;
        border-bottom: 1px solid $light;
        display: flex;
        grid-gap: 1rem;
        grid-template-columns: auto 1fr;
        justify-content: space-between;
        padding: 1rem .5rem;
        transition: .2s;
        height: 100%;

        &:hover {
            background-color: rgba($light, .15);
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            grid-template-columns: repeat(auto-fill, minmax(60vw, 1fr));
        }

        .product-info {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .product-tags {
            > .product-tag {
                background: none;
                display: inline-flex;
            }
        }

        .product-info-secondary {
            display: flex;
            gap: 1rem;
            min-width: 25%;
            height: 100%;

            .product-line-calc {
                display: flex;

                .product-line-calc-currency {
                    font-weight: bold;
                }

                .product-line-calc-separator {
                    font-weight: bold;
                    margin-left: 5px;
                    margin-right: 5px;
                }

                .product-line-calc-label {
                    font-weight: bold;
                }
            }
        }

        .product-image {
            background-color: $white;
            height: 4rem;
            width: 4rem;

            img {
                height: 100%;
                object-fit: contain;
                object-fit: contain;
                width: 100%;
            }
        }

        .product-item-details {
            margin-left: 1rem;
            padding-right: 1rem;
            width: 100%;

            .product-title {
                color: $dark;
                display: inline;
                font-size: 1.125rem;
                font-weight: 500;
                margin-top: .5rem;
                padding-right: 1rem;

                > .product-format {
                    color: $secondary;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }

            .product-title-measure {
                color: #9CA89B;
                font-size: 1.125rem;
                margin-top: auto;
            }

            .product-stock {
                display: inline-flex;
                margin-left: auto;
            }

            .product-description,
            .product-family {
                color: $medium;
                font-size: .875rem;
                font-weight: normal;
                padding-right: 1rem;

                &.product-family {
                    margin-top: .25rem;
                }
            }

            .product-cart-data-wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 7px;
            }

            .product-size,
            .product-weight {
                color: $dark;
                font-size: .75rem;
                line-height: 1;
                padding-top: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: .1rem;
                gap: .25rem;

                .icon-mini {
                    height: .75rem;
                    margin-right: .5rem;
                    width: .75rem;
                }
            }

            .product-size {
                background: rgb(128 128 128 / 5%);
                display: inline-block;
                padding: 6px;
            }

            .product-weight {
                display: inline-block;
            }
        }

        .product-actions-column {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            height: 7.5vh;
            justify-content: flex-end;
        }

        .product-actions-column-secondary {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .product-line-edit-form-wrapper {
                display: flex;

                .product-line-edit-action {
                    background: none;
                    border: none;
                }
            }
        }

        .product-discount-cart {
            background-color: #9CA89B;
            border-radius: 50px;
            color: #FFFFFF;
            display: flex;
            font-size: .625rem;
            font-weight: 500;
            letter-spacing: .03rem;
            padding: .25rem .5rem;
            white-space: nowrap;
        }

        .product-actions {
            align-items: flex-end;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .product-price {
                align-items: baseline;
                color: $secondary;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-end;
                padding-right: 1.125rem;

                .currency {
                    font-size: .825rem;
                    font-weight: 600;
                    margin-right: .25rem;
                }

                .import {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .units-type {
                    color: $medium;
                    font-size: .75rem;
                    margin-top: -.25rem;
                    text-align: right;
                    width: 100%;
                }
            }

            .product-price-modify {
                align-items: baseline;
                color: $secondary;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-end;
                padding-right: 1.125rem;

                .currency {
                    font-size: .825rem;
                    font-weight: 600;
                    margin-right: .25rem;
                }

                .import {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .units-type {
                    color: $medium;
                    font-size: .75rem;
                    margin-top: -.25rem;
                    text-align: right;
                    width: 100%;
                }
            }

            .product-quantity {
                color: $medium;
                font-size: 1rem;
            }
        }
    }
}

.shopping-modal .product-actions {
    display: flex;
    justify-content: center;
}

.product-action-title {
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

.product-action-body {
    display: flex;
    justify-content: center;

    .quantity-units-container {
        >select {
            width: fit-content;
        }
    }
}

.number-input {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: auto;

    input.quantity {
        padding: .25rem 24px .25rem .25rem;
        text-align: right;
        &.changed {
            display: inherit;
        }
    }

    a {
        padding: .25rem;

        img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    input[type='number'].quantity::-webkit-outer-spin-button,
    input[type='number'].quantity::-webkit-inner-spin-button {
        appearance: none;
        background: #FFFFFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
        border-left: 1px solid #BBBBBB;
        bottom: 0;
        opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
        position: absolute;
        right: 0;
        top: 0;
        width: 1em;
    }

    input[type='number'].quantity::-webkit-inner-spin-button:hover,
    input[type='number'].quantity::-webkit-inner-spin-button:active {
        box-shadow: 0 0 2px #00CCFF;
        opacity: .8;
    }

    /* Override browser form filling */
    input:-webkit-autofill {
        background: black;
        color: red;
    }
}

.cart-content form.number-input .quantity {
    display: none;

    &.changed {
        display: inherit;
    }
}

.number-input .increment,
.number-input .decrement,
.delete-icon,
.delete-icon {
    cursor: pointer;
    margin: .5rem;
}

// DISPLAY ONLY INTO SWIPER
.swiper-card-products {
    display: block;
}

// GRID collections
.grid-collections {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
    height: 100%;
    margin-bottom: 3rem;
    width: 100%;

    @include media-breakpoint-down(lg) {
        display: block;
    }

    .collection-item {
        background-image: linear-gradient(rgba($dark, .6), rgba($dark, .6)), url('../../assets/img/bg-03.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        min-height: 15rem;
        padding: 1.5rem;
        width: 100%;

        &:nth-child(1) {
            grid-column: span 4;
            grid-row: span 2;
        }

        &:nth-child(2),
        &:nth-child(3) {
            grid-column: span 2;
        }

        &:nth-child(4),
        &:nth-child(5) {
            grid-column: span 3;
        }

        &:hover {
            background-image: linear-gradient(rgba($dark, .4), rgba($dark, .4)), url('../../assets/img/bg-03.jpg');
            transition-duration: 1s;
        }

        .collection-name {
            color: $white;
            font-size: 1.75rem;
            font-weight: 500;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        .collection-size {
            color: $primary;
            font-size: 1rem;
            font-weight: 100;
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: 1rem;
        }
    }
}

// CUSTOM MADE SECTION
.custom-made-container {
    margin-bottom: 3rem;

    .slogan {
        color: $secondary;
        font-size: .875rem;
        font-weight: 100;
        margin-bottom: .75rem;
        text-transform: uppercase;
    }

    .wow-title {
        font-size: 1.5rem;
        margin-bottom: .25rem;
        text-transform: uppercase;
    }

    .wow-subtitle {
        font-size: 1.25rem;
        margin-bottom: 1.125rem;
        text-transform: uppercase;
    }

    .wow-description {
        color: $medium;
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
}

.img-h-100 {
    height: 100%;
    min-height: 12rem;

    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }

    img {
        height: 100%;
        min-height: 12rem;
        object-fit: cover;
        object-fit: cover;
        width: 100%;
    }
}

// CATALOG
.header-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    padding: 7.625rem 0 3rem;
}

.header-section-detail {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 7.625rem 0 3rem;
}

.header-section-spinner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18em 0;
}

ul#pills-tab {
    justify-content: center;
}

.header-catalog {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    .switch-view-container {
        .nav {
            border: 0;
            justify-content: flex-end;
            min-width: 8rem;
        }

        .nav-tabs .nav-link {
            background-color: rgba($white, 1);
            border: 0;
            border-radius: 0;
            margin-bottom: 0;
            opacity: .5;
            padding: .5rem 1rem;

            &:hover,
            &.active,
            &:focus {
                background-color: rgba($light, .7);
                opacity: 1;
            }
        }
    }
}

.product-stock-labels-container {
    display: flex;
}

.product-stock-label {
    margin-bottom: 5px;
}

.product-outlet-label {
    margin-bottom: 1rem;

    .product-stock {
        margin-bottom: 5px;
    }
}

// PRODUCT DETAIL
.product-stock {
    background-color: $medium;
    color: $white;
    font-size: .625rem;
    font-weight: 500;
    letter-spacing: .03rem;
    padding: .25rem .5rem;
    text-transform: uppercase;

    .icon-mini {
        height: .75rem;
        margin-right: .5rem;
        width: .75rem;
    }
}

.stock-unit{
    margin-left: 5px;
}

.in-stock {
    background-color: $secondary;
}

.no-stock {
    background-color: $tertiary;
}

.stock-in-production {
    background-color: $primary;
}

.product-gallery {
    height: 70vh;

    @include media-breakpoint-down(lg) {
        margin-bottom: 3rem;
    }
}

.product-type-grid {
    list-style: none;
    margin-bottom: 3rem;

    .product-grid-item {
        align-items: center;
        box-shadow: 0 0 1rem rgba($dark, .05);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        max-width: 22rem;
        min-height: 23rem;
        min-width: 15rem;
        padding: 1.25rem;
        transition-duration: .3s;
        width: 100%;

        &:hover {
            box-shadow: 0 1rem 1rem rgba($dark, .15);
            transform: translateY(-.25rem);
        }

        .product-grid-image {
            height: 10rem;
            padding: 0;
            width: 10rem;

            img {
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
                object-fit: contain;
                width: 100%;
            }
        }

        .product-grid-item-details {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
            padding: .5rem;
            text-align: center;

            .product-stock {
                position: absolute;
                top: 10rem;
            }

            .product-grid-title {
                color: $dark;
                font-size: .875rem;
                font-weight: 600;
                text-transform: uppercase;
            }

            .product-grid-family {
                color: $dark;
                font-size: .75rem;
                font-weight: 600;
            }

            .product-grid-finish {
                color: $medium;
                font-size: .75rem;
                font-weight: 600;
            }

            .product-grid-ref {
                color: $medium;
                font-size: .75rem;
                font-weight: normal;
            }

            .product-grid-size,
            .product-grid-weight {
                color: $medium;
                font-size: .75rem;
                font-weight: normal;
                margin-bottom: .5rem;
            }

            .product-grid-size {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                line-height: 1;
            }

            .product-grid-weight {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                line-height: 1;
            }
        }

        .product-grid-item-buy {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: auto;
            width: 100%;

            .product-grid-item-footer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: -1rem;
            }
        }
    }
}

.product-grid-price {
    align-items: baseline;
    color: $secondary;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;

    .product-grid-currency {
        font-size: 1rem;
        font-weight: 600;
        margin-right: .25rem;
    }

    .product-grid-import {
        font-size: 1rem;
        font-weight: 600;
    }

    .product-grid-units-type {
        color: $medium;
        font-size: .625rem;
        margin-top: -.25rem;
        text-align: left;
        width: 100%;
    }
}

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .tag {
        background-color: $light;
        border-radius: 10rem;
        color: $dark;
        font-size: .75rem;
        margin-bottom: .5rem;
        margin-right: .5rem;
        padding: .25em .75em;
        transition: .3s;

        &:hover {
            background-color: $dark;
            color: $white;
        }
    }
}

.colors-container {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1.25rem 1rem;
    grid-template-columns: repeat(auto-fill, minmax(2.25rem, 1fr));
    list-style: none;
    padding: 0;

    .color-item {
        cursor: pointer;
        text-align: center;

        img {
            border-radius: 5rem;
            height: 2rem;
            object-fit: cover;
            object-fit: cover;
            transition: .1s;
            width: 2rem;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        .color-name {
            font-size: .75rem;
            line-height: 1;
            padding-top: .25rem;
            text-align: center;
        }
    }
}

.card-box {
	background-color: rgba($medium,.05);
	padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    .po-input {
        border: none;
        border-bottom: 1px solid $medium;
        display: block;
        background: transparent;
        width: 100%;
        transition: border-bottom-color .5s;

        &:active{
            border-bottom: 1px solid $primary;
        }

        &:focus{
            outline: none;
            border-bottom: 1px solid $primary;
        }

        &:hover{
            border-bottom: 1px solid $primary;
        }
    }

	.card-box-row {
		list-style: none;
		padding: 0;
		color: $dark;
		font-size: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		text-align: center;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
		}
		.card-box-list-item {
			display: flex;
			flex-direction: column;
			padding: .5rem;
            align-items: flex-start;

			@include media-breakpoint-down(md) {
				flex-direction: row;
				margin-bottom: 1rem;
				text-align: left;
                justify-content: space-between;
			}
			p {
				margin-bottom: 0;
			}
		}
	}

    .card-box-list-item > .title{
        font-weight: bold;
    }
}

.card-wow {
    background-color: rgba($medium, .05);
    margin-bottom: 1rem;
    padding: 1rem;
}

.card-wow-title {
    &::before {
        color: $primary;
        content: '[';
    }

    &::after {
        color: $primary;
        content: ']';
    }
}

.disabled-events {
    opacity: .5;
    pointer-events: none;
}

.validating-cart-spinner-container {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
}
// TOTAL PRICE RESUME
.cart-footer {
    background-color: $white;
    bottom: 0;
    box-shadow: 0 0 1rem rgba($dark, .2);
    padding: 1rem;
    position: fixed;
    width: 100%;
    z-index: 100;

    .cart-footer-display {
        align-items: center;
        display: grid;
        grid-auto-rows: auto;
        grid-row-gap: 1rem;
        padding: 0;

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn {
                margin-left: 2rem;
            }
        }

        .total-price-import > .product-price {
            margin: 0;
        }
    }

    .total-price-header {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }

        .total-price-title {
            color: $dark;
            font-size: 1rem;
            font-weight: normal;
        }

        .total-price-import {
            color: $black;
            font-size: 1.25rem;
            font-weight: 900;
        }
    }
}

.status-label {
    background-color: $statusLabel;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    font-size: .625rem;
    font-weight: 500;
    letter-spacing: .03rem;
    padding: .5rem;
}
