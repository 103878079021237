@use 'styles/variables/colors' as variables;

.component-home-searcher-item {
    display: flex;
    gap: .5rem;
    padding: .5rem;
    width: 100%;

    .home-searcher-item-image-container {
        background-color: variables.$white;
        height: 4rem;
        width: 4rem;

        .home-searcher-item-image {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }

    .home-searcher-item-details {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .home-searcher-item-details-header {
            display: flex;
            gap: .5rem;
            align-items: center;
            width: 100%;

            .home-searcher-item-details-header-name {
                color: variables.$dark;
                font-size: 16px;
                font-weight: 600;
            }

            .home-searcher-item-details-header-tag {
                background-color: variables.$medium;
                color: variables.$white;
                font-size: 14px;
                width: fit-content;
                padding: .2rem;
            }
        }

        .home-searcher-item-details-subtitle {
            color: variables.$medium;
            font-size: 14px;
            font-weight: 500;
        }
    }

}
